<template>
  <div v-if="$store.state.fastPermissoes.administrador == 'S' && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
              >Modo admin</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Gerenciamento de administradores</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div
              id="body_conteudo_modo_admin"
              class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0"
            >
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left mb-4">
                <h1 class="aluno_font_color">
                  Gerenciamento de
                  <span>administradores</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <section class="fd-app-welcome text-left mb-4">
                <div>
                  <b-button-group>
                    <b-dropdown
                      right
                      split
                      text="Personalizar emails"
                    >
                      <b-dropdown-item
                        v-if="fastEmailConvite.id_email"
                        @click.prevent="showModal('modalEditarEmailConvite')"
                      >
                        Email do convite
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="fastEmailConfirmacao.id_email"
                        @click.prevent="showModal('modalEditarEmailConfirmacao')"
                      >
                        Email de confirmação do convite
                      </b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                </div>
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibirAdministradores"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Lista de administradores da plataforma ({{ fastAdministradoresTotal.length }})
                            </h6>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click.prevent="showModal('modalTipoCadastro')"
                            >
                              <small>+ Cadastrar administrador</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                            <input
                              type="text"
                              class="form-control form-control-sm"
                              placeholder="Filtrar por nome ou email"
                              @keyup="filtraAdministradores"
                            >
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-8 text-right d-flex justify-content-end align-items-center">
                            <a
                              class="text-success fast-cursor-refresh"
                              @click.prevent="getUsuariosPlataforma($route.params.id_plataforma, 'administradores')"
                            >
                              <b-icon-arrow-counterclockwise font-scale="2" />
                            </a>
                          </div>
                          <div class="col-12 table-responsive mt-2">
                            <table class="table table-sm">
                              <thead class="thead-dark">
                                <tr>
                                  <th><small class="font-weight-bold">Nome</small></th>
                                  <th><small class="font-weight-bold">Email</small></th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Aceito</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ativo</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Adicionado em</small>
                                  </th>
                                  <th class="text-center">
                                    <small class="font-weight-bold">Ações</small>
                                  </th>
                                </tr>
                              </thead>
                              <tbody v-if="fastAdministradoresLoading">
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                    v-html="fastLoadingDados()"
                                  />
                                </tr>
                              </tbody>
                              <tbody v-else-if="fastAdministradoresPaginacao.length">
                                <tr
                                  v-for="(e, index) in fastAdministradoresPaginacao"
                                  :key="index"
                                >
                                  <td class="align-middle">
                                    <small>{{ e.first_name + " " + e.last_name }}</small>                                  
                                  </td>
                                  <td class="align-middle">
                                    <small>{{ e.email }}</small>                                  
                                  </td>
                                  <td class="align-middle text-center">
                                    <small
                                      v-if="e.ativo == 'S'"
                                      class="badge badge-success mr-2"
                                    >sim</small>
                                    <small
                                      v-else
                                      class="badge badge-danger mr-2"
                                    >não</small>                                  
                                  </td>
                                  <td class="align-middle text-center">
                                    <small
                                      v-if="e.ativo == 'S'"
                                      class="badge badge-success mr-2"
                                    >sim</small>
                                    <small
                                      v-else
                                      class="badge badge-danger mr-2"
                                    >Não</small>                               
                                  </td>
                                  <td class="align-middle text-center">
                                    <small v-if="e.data_convite_envio">{{ new Date(e.data_convite_envio).toLocaleDateString() }}</small>  
                                    <small v-else>Sem registro</small>                              
                                  </td>                           
                                  <td class="text-center align-middle">       
                                    <button
                                      class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                      @click="exibeModalEditarUsuario(e)"
                                    >
                                      <small>Detalhes</small>
                                    </button>  
                                  </td>
                                </tr>
                              </tbody>
                              <tbody v-else>
                                <tr>
                                  <td
                                    colspan="6"
                                    class="text-center"
                                  >
                                    Nenhum administrador cadastrado na plataforma
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div class="col-12 text-center mt-4">
                            <Pagination
                              :page-size="20"
                              :items="fastAdministradoresFiltro"
                              @changePage="fastAdministradoresPaginacao = $event"
                            />
                          </div>
                        </div>
                      </div>
                    </div>                  
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.convidarAdministradores"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-6">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Convite de administradores por email <span v-if="fastEmailsConvite.length">({{ fastEmailsConvite.length }})</span>
                            </h6>
                          </div>                        
                          <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                            <button
                              v-if="!fastEmailsConvite.length"
                              class="btn btn-sm btn-primary"
                              @click="showModal('modalListaEmails')"
                            >
                              <small>+ Adicionar lista massiva</small>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-2">
                          <div class="row">
                            <div class="col-12 table-responsive mt-2">
                              <table class="table table-sm">
                                <thead class="thead-dark">
                                  <tr>
                                    <th class="text-center">
                                      <span class="font-weight-bold">Email</span>
                                    </th>
                                    <th class="text-center">
                                      <span class="font-weight-bold">Status</span>
                                    </th>
                                    <th class="text-center">
                                      <span class="font-weight-bold">Ações</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    v-for="(e, index) in fastEmailsConvite"
                                    :key="index"
                                  >
                                    <td class="text-center align-middle">
                                      <div class="text-center">
                                        <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(e.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                        <small>{{ e.first_name + " " + e.last_name }}</small>                                    
                                      </div>
                                      <span>{{ e.email }}</span>                                  
                                    </td>
                                    <td class="text-center align-middle">
                                      <span :class="e.classe">{{ e.status }}</span>                                  
                                    </td>
                                    <td class="text-center align-middle">
                                      <button
                                        v-if="e.code != 'OK'"
                                        class="btn btn-sm btn-danger pt-0 pb-0"
                                        @click="removeEmailLista(e.email)"
                                      >
                                        <span>- Cancelar convite</span>
                                      </button>  
                                      <div
                                        v-if="e.code == 'email_on_system'"
                                        class="text-center"
                                      >
                                        <button
                                          class="btn btn-sm btn-success pt-0 pb-0 mt-2"
                                          @click="cadastrarUsuario('administradores', e)"
                                        >
                                          + Confirmar convite
                                        </button>  
                                      </div>
                                    </td>
                                  </tr>
                                  <tr v-if="fastBtnConvidar">
                                    <td class="text-center align-middle pt-2 pb-2">
                                      <input
                                        v-model="fastEmail.email"
                                        :class="!validateEmail(fastEmail.email) ? 'form-control border border-danger' : 'form-control border border-success'"
                                        type="text"
                                        class="form-control"
                                        placeholder="Insira um email"
                                      >
                                    </td>
                                    <td class="text-center align-middle">
                                      <span :class="fastEmail.classe">{{ fastEmail.status }}</span>
                                    </td>
                                    <td class="text-center align-middle">
                                      <button
                                        class="btn btn-sm btn-success pt-0 pb-0"
                                        @click="adicionaEmailLista()"
                                      >
                                        <span>+ Adicionar</span>
                                      </button>  
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>                                         
                            <div class="col-12 mt-2 mb-2">
                              <div class="form-group mb-0">
                                <button
                                  v-if="fastEmailsConvite.length && fastBtnConvidar"
                                  href="#"
                                  type="button"
                                  class="btn btn-primary mr-1"
                                  @click.prevent="convidarUsuarios()"
                                >
                                  <small>Enviar convites</small>                                
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click.prevent="cancelaConviteUsuarios()"
                                >
                                  <small>Voltar</small>                                
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.cadastrarAdministradores"
                    class="col-12"
                  >                  
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Cadastrar novo administrador
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-2">
                          <!-- Usuário já cadastrado no sistema ou na plataforma não pode ter seus dados alterados -->
                          <div
                            v-if="fastEmailCadastro.code != 'email_on_system' && fastEmailCadastro.code != 'email_on_plataform'"
                            class="row"
                          >
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Nome do administrador <small class="text-danger">*</small></label>
                              <input
                                v-model="modelCadastroUsuario.fast_usuario.first_name"
                                type="text"
                                class="form-control"
                                maxlength="50"
                                :class="!modelCadastroUsuario.fast_usuario.first_name ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>   
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Sobrenome do administrador <small class="text-danger">*</small></label>
                              <input
                                v-model="modelCadastroUsuario.fast_usuario.last_name"
                                type="text"
                                class="form-control"
                                maxlength="50"
                                :class="!modelCadastroUsuario.fast_usuario.last_name ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>   
                            <div class="col-sm-12 col-md-12 col-lg-6 mb-4">
                              <label>Email <small class="text-danger">*</small></label>
                              <input
                                v-model="modelCadastroUsuario.fast_usuario.email"
                                type="text"
                                class="form-control"
                                maxlength="50"
                                :class="!modelCadastroUsuario.fast_usuario.email ? 'form-control border border-danger' : 'form-control'"
                              >
                            </div>           
                            <div class="col-sm-12 col-md-12 col-lg-6 text-center mb-4">
                              <div class="fd-app-curso-aulas mt-0">
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Enviar convite por email</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input   
                                            v-model="modelCadastroUsuario.envia_email"                                        
                                            type="checkbox"                                           
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>  
                            </div>   
                            <div class="col-12">
                              <div class="fd-app-curso-aulas">
                                <h5>Permissões</h5>
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row">
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita cursos</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_cursos"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita usuários</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_usuarios"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita professores</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_professores"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita admins</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_admins"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita alunos</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_alunos"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita plataforma</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_plataforma"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita matriz</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_matriz"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita financeiro</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="modelCadastroUsuario.fast_plataforma_usuario.edita_financeiro"
                                            type="checkbox"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>  
                          <!-- Usuário existe -->
                          <div
                            v-else
                            class="row"
                          >
                            <div class="col-12 mb-2 text-center">
                              <div class="text-center">
                                <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(fastEmailCadastro.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                <small>{{ fastEmailCadastro.first_name + " " + fastEmailCadastro.last_name }}</small>                                    
                              </div>
                              <p>{{ fastEmailCadastro.email }}</p> 
                              <div
                                v-if="fastEmailCadastro.administrador == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Administrador
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_usuarios == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita usuários
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_alunos == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita alunos
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_professores == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita professores
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_admins == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita administradores
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_matriz == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita matriz
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_plataforma == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita plataforma
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_financeiro == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita financeiro
                              </div>
                              <div
                                v-if="fastEmailCadastro.edita_cursos == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Edita cursos
                              </div>
                              <div
                                v-if="fastEmailCadastro.professor == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Professor
                              </div>
                              <div
                                v-if="fastEmailCadastro.aluno == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Aluno
                              </div>
                              <div
                                v-if="fastEmailCadastro.tesouraria == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Tesouraria
                              </div>
                              <div
                                v-if="fastEmailCadastro.secretario == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Secretario
                              </div>
                              <div
                                v-if="fastEmailCadastro.responsavel == 'S'"
                                class="text-success"
                              >
                                <b-icon-check-circle-fill /> Responsavel
                              </div> 

                              <!-- O convite para um usuário existente só pode ser feito se o usuário não estiver na plataforma -->
                              <button
                                v-if="fastEmailCadastro.code != 'email_on_plataform'"
                                class="btn btn-sm btn-success pt-0 pb-0 mt-2"
                                @click="cadastrarUsuarioCompleto('administradores', fastEmailCadastro)"
                              >
                                + Confirmar convite
                              </button> 
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 text-center">
                              <p :class="fastEmailCadastro.classe">
                                {{ fastEmailCadastro.status }}
                              </p>                            
                            </div>
                          </div>
                          <div class="row">                                        
                            <div class="col-12 mt-2 mb-2">
                              <div class="form-group mb-0">
                                <button
                                  v-if="fastEmailCadastro.btnCadastrar"
                                  href="#"
                                  type="button"
                                  class="btn btn-primary mr-1"
                                  @click="verificaCadastrarUsuarioCompleto('administradores')"
                                >
                                  <small>Cadastrar usuário</small>                                
                                </button>
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click.prevent="cancelaConviteUsuarios()"
                                >
                                  <small>Cancelar</small>                                
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.editarAdministradores"
                    class="col-12"
                  >
                    <div class="card shadow mb-4">
                      <div class="card-header py-3">
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h6 class="m-0 font-weight-bold text-primary">
                              Detalhes do administrador
                            </h6>
                          </div>                        
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="col-12 mt-2">
                          <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <div class="card border-left-primary h-100">                    
                                <div class="card-body">
                                  <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                      <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">                                              
                                        <span v-if="fastUsuarioEditar.first_name">{{ fastUsuarioEditar.first_name + " " + fastUsuarioEditar.last_name }}</span>     
                                        <span v-else>Nome não registrado</span>  
                                      </div>
                                      <div class="h5 mb-0 font-weight-bold text-success">
                                        <span>{{ fastUsuarioEditar.email }}</span>  
                                      </div>
                                      <div class="text-xs font-weight-bold text-secondary text-uppercase mt-2">   
                                        <div
                                          v-if="fastUsuarioEditar.administrador == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Administrador
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_usuarios == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita usuários
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_alunos == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita alunos
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_professores == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita professores
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_admins == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita administradores
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_matriz == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita matriz
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_plataforma == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita plataforma
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_financeiro == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita financeiro
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.edita_cursos == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Edita cursos
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.professor == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Professor
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.aluno == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Aluno
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.tesouraria == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Tesouraria
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.secretario == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Secretario
                                        </div>
                                        <div
                                          v-if="fastUsuarioEditar.responsavel == 'S'"
                                          class="text-success"
                                        >
                                          <b-icon-check-circle-fill /> Responsavel
                                        </div>                                                                                             
                                      </div>
                                    </div>
                                    <div class="col-auto">
                                      <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(fastUsuarioEditar.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-sm-12 col-md-12 col-lg-6">
                              <div class="card border-left-primary h-100">                    
                                <div class="card-body">
                                  <div class="row no-gutters align-items-center">
                                    <div class="col mr-2">
                                      <div
                                        v-if="fastUsuarioEditar.data_convite_envio"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        Adicionado em <span>{{ new Date(fastUsuarioEditar.data_convite_envio).toLocaleDateString() }}</span>  
                                      </div>
                                      <div
                                        v-if="fastUsuarioEditar.data_convite_reenvio"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        Reenviado em <span>{{ new Date(fastUsuarioEditar.data_convite_reenvio).toLocaleDateString() }}</span>  
                                      </div>
                                      <div class="text-xs font-weight-bold text-secondary text-uppercase mb-1">                                              
                                        Aceito: <span
                                          v-if="fastUsuarioEditar.aceito == 'S'"
                                          class="text-success"
                                        >Sim</span>  
                                        <span
                                          v-else
                                          class="text-danger"
                                        >Não</span>                                                
                                      </div>
                                      <div
                                        v-if="fastUsuarioEditar.data_aceite && fastUsuarioEditar.aceito == 'S'"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        Data do aceite: <span>{{ new Date(fastUsuarioEditar.data_aceite).toLocaleDateString() }}</span>                                                 
                                      </div>
                                      <div
                                        v-if="fastUsuarioEditar.token"
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >                                              
                                        <p v-if="fastUsuarioEditar.token == 'desativado'">
                                          <span class="text-danger">Desativado manualmente</span>
                                        </p> 
                                        <p v-else-if="fastUsuarioEditar.token == 'ativado'">
                                          <span class="text-success">Ativado manualmente</span>
                                        </p> 
                                        <p v-else>
                                          Convite pendente: <span class="text-warning">Sim</span>
                                        </p>                                                          
                                      </div>
                                      <div
                                        v-else
                                        class="text-xs font-weight-bold text-secondary text-uppercase mb-1"
                                      >  
                                        <p>Convite pendente: <span class="text-danger"> Não </span></p> 
                                      </div>
                                    </div>
                                    <div class="col-auto">
                                      <div
                                        v-if="fastUsuarioEditar.ativo == 'S'"
                                        class="h5 mb-0 font-weight-bold text-success"
                                      >
                                        <span
                                          v-if="fastUsuarioEditar.status == 'A'"
                                          class="btn btn-success"
                                        >Ativado</span>  
                                        <span
                                          v-else-if="fastUsuarioEditar.status == 'B'"
                                          class="btn btn-danger"
                                        >Conta bloqueada</span>  
                                        <span
                                          v-else-if="fastUsuarioEditar.status == 'I'"
                                          class="btn btn-danger"
                                        >Conta inativa</span>  
                                      </div>
                                      <div
                                        v-else
                                        class="h5 mb-0 font-weight-bold text-danger"
                                      >
                                        <span class="btn btn-danger">Desativado</span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            v-if="$store.state.fastPermissoes.id_usuario != fastUsuarioEditar.id_usuario"
                            class="row mt-4"
                          >
                            <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-warning"
                                @click="showModal('modalUsuarioReenviar')"
                              >
                                <b-icon-arrow-counterclockwise font-scale="3" />                             
                                <h6 class="mt-2">
                                  Reenviar convite
                                </h6>
                              </div>
                            </div>
                            <div
                              v-if="fastUsuarioEditar.ativo == 'S'"
                              class="col-sm-12 col-md-12 col-lg-3 text-center"
                            >
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-danger"
                                @click="showModal('modalUsuarioDesativar')"
                              >
                                <b-icon-arrow-down-circle font-scale="3" />
                                <h6 class="mt-2">
                                  Desativar
                                </h6>
                              </div>
                            </div>
                            <div
                              v-else
                              class="col-sm-12 col-md-12 col-lg-3 text-center"
                            >
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-success"
                                @click="showModal('modalUsuarioAtivar')"
                              >
                                <b-icon-arrow-up-circle font-scale="3" />
                                <h6 class="mt-2">
                                  Ativar
                                </h6>
                              </div>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-3 text-center">
                              <div
                                class="fast-cursor-pointer p-4 rounded border text-secondary"
                                style="cursor: not-allowed; background: rgb(154 154 154)"
                                @click.prevent="exibeToasty('Para excluir consulte um administrador da plataforma', 'error')"
                              >
                                <b-icon-trash font-scale="3" />
                                <h6 class="mt-2">
                                  Excluir
                                </h6>
                              </div>
                            </div>
                          </div>

                          <div
                            v-if="$store.state.fastPermissoes.id_usuario != fastUsuarioEditar.id_usuario"
                            class="row mt-4"
                          >
                            <div class="col-12">
                              <div class="fd-app-curso-aulas">
                                <h5>Permissões</h5>
                                <div class="fd-app-curso-aulas-list mt-0">
                                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 row">
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita cursos</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_cursos"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita usuários</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_usuarios"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita professores</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_professores"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita admins</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_admins"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita alunos</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_alunos"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita plataforma</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_plataforma"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita matriz</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_matriz"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                    <div class="col-sm-12 col-md-12 col-lg-3 mb-3">
                                      <div class="col-12 text-center mb-1">
                                        <span>Edita financeiro</span>
                                      </div>
                                      <div class="col-12 text-center">
                                        <label class="switch">
                                          <input
                                            v-model="fastUsuarioEditar.edita_financeiro"
                                            type="checkbox"
                                            @change="alteraPermissoesUsuario()"
                                          >
                                          <span class="slider round" />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-4">                                        
                            <div class="col-12 mt-2 mb-2">
                              <div class="form-group mb-0">
                                <button
                                  href="#"
                                  type="button"
                                  class="btn btn-secondary mr-1"
                                  @click="alteraFastNavegacao('exibirAdministradores')"
                                >
                                  <small>Voltar</small>                                
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">
                <a
                  id="btn_voltar_modo_admin"
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/admin'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/admin')"
                >
                  <b-icon-arrow-return-left />
                  Voltar
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalTipoCadastro"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Escolha uma Opção</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalTipoCadastro')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div
                    class="card fast-cursor-pointer"
                    @click.prevent="hideModal('modalTipoCadastro'); alteraFastNavegacao('convidarAdministradores')"
                  >
                    <div class="card-body text-center">
                      <h5 class="card-title ">
                        Envio de emails
                      </h5>                            
                      <b-icon-card-checklist font-scale="4" />
                      <p class="card-text mt-2">
                        Convide os administradores através do email
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-6">
                  <div
                    class="card fast-cursor-pointer"
                    @click.prevent="hideModal('modalTipoCadastro'); alteraFastNavegacao('cadastrarAdministradores')"
                  >
                    <div class="card-body text-center">
                      <h5 class="card-title">
                        Cadastro Individual
                      </h5>
                      <b-icon-file-earmark-person-fill font-scale="4" />
                      <p class="card-text mt-2">
                        Cadastre individualmente o administrador 
                      </p>                        
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalListaEmails"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Insira a lista de emails</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalListaEmails')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <textarea
                v-model="fastListaEmailsMassica"
                class="form-control"
                rows="10"
              />
              <small class="text-info">Insira um email embaixo do outro</small>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button
                class="btn btn-sm btn-primary"
                @click="separaListaEmailsMassiva()"
              >
                <small>Inserir emails</small>                
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioExcluir"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Excluir usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioExcluir()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioDesativar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Desativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioDesativar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioDesativar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioAtivar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Ativar usuário?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioAtivar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="confirmaUsuarioAtivar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalUsuarioReenviar"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-6 pl-4 pr-4">
          <h4>Reenviar convite?</h4>
        </div>
        <div class="col-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalUsuarioReenviar')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click="confirmaUsuarioReenviar()"
                >
                  Confirmar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarEmailConvite"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmailConvite')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastEmailConvite.nome_email"
                class="form-control"
                type="text"
                readonly
              >
            </div>

            <div
              v-if="fastEmailConvite.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email para envio</label>
              <input
                v-model="fastEmailConvite.email_envio"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="fastEmailConvite.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastEmailConvite.email_copia"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="!fastEmailConvite.obrigatorio"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 p-0">
                    <label class="switch mr-2">
                      <input
                        v-model="fastEmailConvite.ativo"
                        :checked="fastEmailConvite.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastEmailConvite.descricao }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small
                  class="m-0 font-weight-bold text-secondary"
                >Você pode usar as seguintes palavras especiais no seu texto:</small>
                <table>
                  <tbody v-if="fastEmailConvite.variaveis_disponiveis.split(',').length">
                    <tr>
                      <td
                        v-for="(variavel, index) in fastEmailConvite.variaveis_disponiveis.split(',')"
                        :key="index"
                        class="pr-4"
                      >
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ variavel }}</span> 
                        </small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="pr-4">
                        Nenhuma variável disponível
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info">Corpo do email que será enviado, utilize o editor para inserir links, imagens e customizar o texto. Ou personalize o HTML do corpo do email clicando aqui: <button
                  class="btn btn-sm btn-primary pt-0 pb-0"
                  @click="showModal('modalEditarHTMLEmailConvite', 'modalEditarEmailConvite')"
                ><b-icon-code-slash /> Editar HTML</button></small>
              </div>              
              <quill-editor
                v-model="fastEmailConvite.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarEmail(fastEmailConvite)"
                >
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmailConvite"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarEmailConvite', 'modalEditarHTMLEmailConvite')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastEmailConvite.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="showModal('modalEditarEmailConvite', 'modalEditarHTMLEmailConvite')"
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarEmailConfirmacao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4">
          <h4>Editar email</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarEmailConfirmacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>

        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
              <label class="text-secondary font-weight-bold">Nome</label>
              <input
                :value="fastEmailConfirmacao.nome_email"
                class="form-control"
                type="text"
                readonly
              >
            </div>

            <div
              v-if="fastEmailConfirmacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email para envio</label>
              <input
                v-model="fastEmailConfirmacao.email_envio"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="fastEmailConfirmacao.email_destino == 'AD'"
              class="col-sm-12 col-md-12 col-lg-3 mt-2"
            >
              <label class="text-secondary font-weight-bold">Email cópia</label>
              <input
                v-model="fastEmailConfirmacao.email_copia"
                class="form-control"
                type="text"
              >
            </div>
            <div
              v-if="!fastEmailConfirmacao.obrigatorio"
              class="col-sm-12 col-md-12 col-lg-12 mt-2"
            >
              <label class="text-secondary font-weight-bold">Habilitar</label>
              <div class="fd-app-curso-aulas mt-0">
                <div class="fd-app-curso-aulas-list mt-0">
                  <div class="fd-app-curso-aulas-list-item shadow-none border-0 p-0">
                    <label class="switch mr-2">
                      <input
                        v-model="fastEmailConfirmacao.ativo"
                        :checked="fastEmailConfirmacao.ativo"
                        type="checkbox"
                      >
                      <span class="slider round" />
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <label class="text-secondary font-weight-bold">Descrição</label>
              <p class="m-0 text-secondary">
                {{ fastEmailConfirmacao.descricao }}
              </p>
            </div>
            <div class="col-12 pt-2 pb-2">
              <div class="alert alert-info">
                <small
                  class="m-0 font-weight-bold text-secondary"
                >Você pode usar as seguintes palavras especiais no seu texto:</small>
                <table>
                  <tbody v-if="fastEmailConfirmacao.variaveis_disponiveis.split(',').length">
                    <tr>
                      <td
                        v-for="(variavel, index) in fastEmailConfirmacao.variaveis_disponiveis.split(',')"
                        :key="index"
                        class="pr-4"
                      >
                        <small class="m-0 text-secondary">
                          <span class="font-weight-bold">{{ variavel }}</span> 
                        </small>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td class="pr-4">
                        Nenhuma variável disponível
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div class="col-12">
              <div class="mb-2">
                <small class="text-info">Corpo do email que será enviado, utilize o editor para inserir links, imagens e customizar o texto. Ou personalize o HTML do corpo do email clicando aqui: <button
                  class="btn btn-sm btn-primary pt-0 pb-0"
                  @click="showModal('modalEditarHTMLEmailConfirmacao', 'modalEditarEmailConfirmacao')"
                ><b-icon-code-slash /> Editar HTML</button></small>
              </div>              
              <quill-editor
                v-model="fastEmailConfirmacao.html_email"
                :options="editorOption"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12 mt-4">
              <div>
                <button
                  class="btn btn-primary"
                  @click="editarEmail(fastEmailConfirmacao)"
                >
                  <small>Salvar alterações</small>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <modal
      name="modalEditarHTMLEmailConfirmacao"
      :width="modalWidthMd"
      :shift-y="0.1"
      height="auto"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-8 pl-4 pr-4">
          <h4>Editar HTML do email</h4>
        </div>
        <div class="col-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="showModal('modalEditarEmailConfirmacao', 'modalEditarHTMLEmailConfirmacao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <textarea
            v-model="fastEmailConfirmacao.html_email"
            class="form-control"
            rows="10"
          />
        </div>
        <div class="col-12 pt-4 pr-4 pb-0 pl-4">
          <button
            class="btn btn-secondary mt-2 ml-2"
            @click.prevent="showModal('modalEditarEmailConfirmacao', 'modalEditarHTMLEmailConfirmacao')"
          >
            Voltar
          </button>
        </div>
      </div>
    </modal>

    <!-- Notificações -->
    <notifications
      group="foo"
      position="bottom right"
    />
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
export default {
  name: "HomeInternoAdminAdministradores",
  components: {
    Pagination, quillEditor
  },
  mixins: [methods],
  data: function() {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      urlFastEAD: settings.endFastEad,
      // Navegação entre paginas
      fastNavegacao: {
        exibirAdministradores: true,
        convidarAdministradores: false,
        cadastrarAdministradores: false,
        editarAdministradores: false,
      },
      // Lista de administradores
      fastAdministradoresTotal: [],
      fastAdministradoresFiltro: [],
      fastAdministradoresPaginacao: [],
      fastAdministradoresLoading: [],
      // Convite por email
      modelConviteUsuario: {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          email: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        }
      },
      fastEmailsConvite: [],
      fastEmail: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "Aguardando...",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        email: "",
        administrador: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: ""
      },
      fastBtnConvidar: true,
      fastListaEmailsMassica: "",
      // Cadastro individual
      modelCadastroUsuario: {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: true,
          edita_usuarios: true,
          edita_professores: true,
          edita_plataforma: true,
          edita_matriz: true,
          edita_financeiro: true,
          edita_alunos: true,
          edita_admins: true,
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          id_usuario: 0,
          first_name: "",
          last_name: "",
          email: ""
        },
        fast_pessoa: {
          cpf_cnpj: "",
          datanascimento_abertura: null,
          banco: "",
          codigo_banco: "",
          sexo: "",
        },
        fast_pessoa_endereco: {
          tipo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: ""
        },
        fast_pessoa_telefone: {
          tipo: 1,
          telefone: "",
          ramal: ""
        },
        fast_usuario_parametro: {
          image: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        },
        envia_email: true
      },
      fastEmailCadastro: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        administrador: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: "",
        btnCadastrar: true
      },
      // Editar usuário
      fastUsuarioEditar: {
        id_usuario: "",
        first_name: "",
        last_name: "",
        image: "",
        ativo: "",
        aceito: "",
        data_convite_envio: "",
        data_aceite: null,
        token: "",
        administrador: "",
        edita_cursos: "",
        edita_usuarios: "",
        edita_professores: "",
        edita_plataforma: "",
        edita_matriz: "",
        edita_financeiro: "",
        edita_alunos: "",
        edita_admins: "",
        professor: "",
        aluno: "",
        tesouraria: "",
        secretario: "",
        responsavel: ""
      },
      // Emails
      fastEmailConvite: {
        assunto: "",
        ativo: "",
        customizado: "",
        data_customizacao: "",
        data_restauracao_padrao: "",
        email_copia: "",
        email_destino: "",
        email_envio: "",
        html_email: "",
        html_email_padrao: "",
        id_email: "",
        id_email_restauracao: "",
        id_plataforma: 0,
        id_usuario_customizacao: "",
        id_usuario_restauracao: "",
        nome_email: "",
        descricao: "",
        obrigatorio: false,
        modulo: "",
        variaveis_disponiveis: ""
      },
      fastEmailConfirmacao: {
        assunto: "",
        ativo: "",
        customizado: "",
        data_customizacao: "",
        data_restauracao_padrao: "",
        email_copia: "",
        email_destino: "",
        email_envio: "",
        html_email: "",
        html_email_padrao: "",
        id_email: "",
        id_email_restauracao: "",
        id_plataforma: 0,
        id_usuario_customizacao: "",
        id_usuario_restauracao: "",
        nome_email: "",
        descricao: "",
        obrigatorio: false,
        modulo: "",
        variaveis_disponiveis: ""
      },
       // Editor de texto
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // toggled buttons
            [{ header: 1 }, { header: 2 }], // custom button values
            [{ list: "ordered" }, { list: "bullet" }, { align: [] }, "image"],
          ],
        },
      },
    };
  },
  mounted: function() {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'administrador')
        .then(() => {
          if (this.$store.state.fastPermissoes.ativo == "S" && this.$store.state.fastPermissoes.administrador == "S") {
            this.getUsuariosPlataforma(this.$route.params.id_plataforma, 'administradores')  
            this.getFastPlataformaEmail(this.$route.params.id_plataforma, 'Convite para administrador', 'Administrador aceitou o convite')

            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Você não possui permissões", "error");
          }
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    // Lista de usuários
    async getUsuariosPlataforma(id_plataforma, perfil){
      this.fastAdministradoresLoading = true
      let param = `id_plataforma=${id_plataforma}&administrador=N&professor=N&aluno=N&tesouraria=N&secretario=N&responsavel=N`
      switch (perfil) {
        case 'administradores':
          param = `id_plataforma=${id_plataforma}&administrador=S&professor=&aluno=&tesouraria=&secretario=&responsavel=`
          break;
        case 'professores':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=S&aluno=&tesouraria=&secretario=&responsavel=`
          break;
        case 'administradores':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=S&tesouraria=&secretario=&responsavel=`
          break;
        case 'tesouraria':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=S&secretario=&responsavel=`
          break;
        case 'secretario':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=S&responsavel=`
          break;
        case 'responsavel':
          param = `id_plataforma=${id_plataforma}&administrador=&professor=&aluno=&tesouraria=&secretario=&responsavel=S`
          break;
        default:
          break;
      }
      this.promiseGetFastApi("api/fast_plataforma_usuario/lista_usuarios_plataforma", param).then(obj => {
        console.log("getUsuariosPlataforma", obj)
        if (obj.length) {
          this.fastAdministradoresTotal = obj
          this.fastAdministradoresFiltro = obj
        }
        else {
          this.fastAdministradoresTotal = []
          this.fastAdministradoresFiltro = []
        }          
        this.fastAdministradoresLoading = false  
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar administradores", "error");
        this.fastAdministradoresLoading = false
      })
    },
    filtraAdministradores(e) {
      let text = e.target.value;
      this.fastAdministradoresFiltro = this.fastAdministradoresTotal.filter((e) => {
        return e.first_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.last_name.toLowerCase().indexOf(text.toLowerCase()) != "-1" || e.email.toLowerCase().indexOf(text.toLowerCase()) != "-1";
      });
    },
    // Convite através de email
    adicionaEmailLista(){
      let email = this.fastEmail.email.trim()
      if (this.validateEmail(email)) {
        let emailInserido = this.fastEmailsConvite.filter(e => e.email == email)
        if (!emailInserido.length) {
          this.fastEmailsConvite.push({ id_usuario: "", id_pessoa: 0, email: email, status: "Adicionado", classe: "text-success", code: "", first_name: "Nome", last_name: "não identificado", image: "" })
          this.fastEmail.id_usuario = ""
          this.fastEmail.id_pessoa = ""
          this.fastEmail.email = ""
          this.fastEmail.status = "Aguardando..."
          this.fastEmail.classe = "text-info"
          this.fastEmail.code = ""
          this.fastEmail.first_name = "Nome"
          this.fastEmail.last_name = "não identificado"
          this.fastEmail.image = ""
          this.fastEmail.administrador = ""
          this.fastEmail.professor = ""
          this.fastEmail.aluno = ""
          this.fastEmail.tesouraria = ""
          this.fastEmail.secretario = ""
          this.fastEmail.responsavel = ""
        } else {
          this.exibeToasty("Email já consta na lista", "error");
        }        
        
      } else {
        this.exibeToasty("Insira um email válido", "error");
      }      
    },
    removeEmailLista(email){
      this.fastEmailsConvite = this.fastEmailsConvite.filter(function(e){ 
          return e.email != email; 
      });
    },
    convidarUsuarios(){
      this.fastBtnConvidar = false
      this.fastEmailsConvite.forEach(e => {
        e.status = "Verificando email na base..."
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${e.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            e.status = retorno.description
            e.classe = "text-danger"
          } else {
            e.status = retorno.description
            e.classe = "text-success"
            e.code = retorno.code
          }      

          switch (retorno.code) {
            case "email_on_plataform":
              e.status = "Não é possível convidar pois o usuário já está associado à plataforma"              
              e.classe = "text-danger"
              e.first_name = retorno.first_name
              e.last_name = retorno.last_name
              e.image = retorno.image
              e.id_usuario = retorno.id_usuario
              e.id_pessoa = retorno.id_pessoa
              break;
            case "email_on_system":
              e.status = "Email já cadastrado no sistema. Confirme o convite"
              e.classe = "text-warning"
              e.first_name = retorno.first_name
              e.last_name = retorno.last_name
              e.image = retorno.image
              e.id_usuario = retorno.id_usuario
              e.id_pessoa = retorno.id_pessoa
              break;
            case "email_unknown":
              e.status = "Cadastrando novo usuário. Aguarde..."
              e.classe = "text-success"
              this.cadastrarUsuario('administradores', e)
              break;          
            default:
              e.status = "Código de retorno não identificado"
              e.classe = "text-danger"
              break;
          }   

        }).catch(e => {         
          e.status = "Erro ao verificar email"
          e.classe = "text-danger"
        })
      })
    },
    cancelaConviteUsuarios(){
      this.fastBtnConvidar = true; 
      this.fastEmailsConvite = []
      this.fastEmailCadastro = {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        status: "",
        classe: "text-info",
        code: "",
        first_name: "Nome",
        last_name: "não identificado",
        image: "",
        btnCadastrar: true
      };
      this.modelCadastroUsuario = {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: ""
        },
        fast_usuario: {
          first_name: "",
          last_name: "",
          email: ""
        },
        fast_pessoa: {
          cpf_cnpj: "",
          datanascimento_abertura: null,
          banco: "",
          codigo_banco: "",
          sexo: "",
        },
        fast_pessoa_endereco: {
          tipo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: ""
        },
        fast_pessoa_telefone: {
          tipo: 1,
          telefone: "",
          ramal: ""
        },
        fast_usuario_parametro: {
          image: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        },
        envia_email: true
      };
      this.alteraFastNavegacao('exibirAdministradores');       
    },
    separaListaEmailsMassiva() {
      if (this.fastListaEmailsMassica.length) {
        let listaEmails = this.fastListaEmailsMassica.split("\n");
        listaEmails.forEach((e) => e = e.trim());
        if (listaEmails.length) {
          listaEmails.forEach(email => {
            console.log(this.validateEmail(email))
            let emailInserido = this.fastEmailsConvite.filter(e => e.email == email)            
            if (!emailInserido.length) {
              if (this.validateEmail(email)) this.fastEmailsConvite.push({ email: email, status: "Adicionado", classe: "text-success", code: "", first_name: "Nome", last_name: "não identificado", image: "" })
              else
                this.exibeToasty(`O email ${email} é inválido`, "error");
            } else {
              this.exibeToasty(`O email ${email} já foi inserido`, "error");
            }          
          })
        }
        this.hideModal('modalListaEmails')
        this.fastListaEmailsMassica = ""
      } else {
        this.exibeToasty("Insira ao menos um email válido", "error");
      }
    },
    cadastrarUsuario(perfil, fastEmail){
      fastEmail.status = "Cadastrando usuário. Aguarde..."
      switch (perfil) {
        case 'professores':
          
          break;
        case 'administradores':
          this.modelConviteUsuario = {
            fast_plataforma_usuario: {
              id_plataforma: this.$route.params.id_plataforma,
              administrador: "S",
              edita_cursos: "S",
              edita_usuarios: "S",
              edita_professores: "S",
              edita_plataforma: "S",
              edita_matriz: "S",
              edita_financeiro: "S",
              edita_alunos: "S",
              edita_admins: "S",
              professor: "N",
              aluno: "N",
              tesouraria: "N",
              secretario: "N",
              responsavel: "N"
            },
            fast_usuario: {
              email: fastEmail.email
            },
            fast_plataforma_professor: {
              first_name: "",
              last_name: "",
              licenciatura: "",
              image: ""
            }
          }

          this.promisePostFastApi(this.modelConviteUsuario, "api/fast_plataforma_usuario/convida_usuario").then(res => {
            console.log("cadastrarUsuario", res)
            const retorno = JSON.parse(res)
            if (retorno.error) {
              fastEmail.status = retorno.description
              fastEmail.classe = "text-danger"
            } else {
               if (retorno.code == "OK") {
                 fastEmail.status = "Convite enviado com sucesso"
                 fastEmail.code = "OK"
                 fastEmail.classe = "text-success"                 
               } else {
                 fastEmail.status = "Erro desconhecido. Contate o administrador do sistema."
                fastEmail.classe = "text-danger"
               }
            }
          }).catch(e => {         
            fastEmail.status = "Erro ao convidar aluno"
            fastEmail.classe = "text-danger"
          })
          
          break;
        case 'tesouraria':
          
          break;
        case 'secretario':
          
          break;
        case 'responsavel':
          
          break;
        default:
          break;
      }
    },
    // Cadastro individual
    verificaCadastrarUsuarioCompleto(perfil){
      let erros = []
      if (!this.modelCadastroUsuario.fast_usuario.first_name) erros.push("O nome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.last_name) erros.push("O sobrenome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.email) erros.push("O email é obrigatório")

      switch (perfil) {
        case 'professores':
          
          break;
        case 'administradores':
          this.modelCadastroUsuario.fast_plataforma_usuario.administrador = "S"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_cursos = this.modelCadastroUsuario.fast_plataforma_usuario.edita_cursos ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_usuarios = this.modelCadastroUsuario.fast_plataforma_usuario.edita_usuarios ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_professores = this.modelCadastroUsuario.fast_plataforma_usuario.edita_professores ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_plataforma = this.modelCadastroUsuario.fast_plataforma_usuario.edita_plataforma ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_matriz = this.modelCadastroUsuario.fast_plataforma_usuario.edita_matriz ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_financeiro = this.modelCadastroUsuario.fast_plataforma_usuario.edita_financeiro ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_alunos = this.modelCadastroUsuario.fast_plataforma_usuario.edita_alunos ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.edita_admins = this.modelCadastroUsuario.fast_plataforma_usuario.edita_admins ? "S" : "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.professor = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.aluno = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.tesouraria = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.secretario = "N"
          this.modelCadastroUsuario.fast_plataforma_usuario.responsavel = "N"
          break;
        case 'tesouraria':
          
          break;
        case 'secretario':
          
          break;
        case 'responsavel':
          
          break;
        default:
          break;
      }

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastEmailCadastro.btnCadastrar = false

        this.fastEmailCadastro.status = "Verificando email na base..."
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${this.modelCadastroUsuario.fast_usuario.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            this.exibeToasty(retorno.description, "error")
          } else {
            this.fastEmailCadastro.code = retorno.code

            switch (retorno.code) {
            case "email_on_plataform":
              this.fastEmailCadastro.status = "Não é possível convidar pois o usuário já está associado à plataforma"              
              this.fastEmailCadastro.classe = "text-danger"
              this.fastEmailCadastro.first_name = retorno.first_name
              this.fastEmailCadastro.last_name = retorno.last_name
              this.fastEmailCadastro.image = retorno.image
              this.fastEmailCadastro.id_usuario = retorno.id_usuario
              this.fastEmailCadastro.id_pessoa = retorno.id_pessoa              
              this.fastEmailCadastro.email = retorno.email
              this.fastEmailCadastro.administrador = retorno.administrador
              this.fastEmailCadastro.professor = retorno.professor
              this.fastEmailCadastro.aluno = retorno.aluno
              this.fastEmailCadastro.tesouraria = retorno.tesouraria
              this.fastEmailCadastro.secretario = retorno.secretario
              this.fastEmailCadastro.responsavel = retorno.responsavel
              break;
            case "email_on_system":
              this.fastEmailCadastro.status = "Email já cadastrado no sistema. Confirme o convite"
              this.fastEmailCadastro.classe = "text-warning"
              this.fastEmailCadastro.first_name = retorno.first_name
              this.fastEmailCadastro.last_name = retorno.last_name
              this.fastEmailCadastro.image = retorno.image
              this.fastEmailCadastro.id_usuario = retorno.id_usuario
              this.fastEmailCadastro.email = retorno.email
              break;
            case "email_unknown":
              this.fastEmailCadastro.status = "Cadastrando novo usuário. Aguarde..."
              this.fastEmailCadastro.classe = "text-success"
              this.cadastrarUsuarioCompleto()
              break;          
            default:
              e.status = "Código de retorno não identificado"
              e.classe = "text-danger"
              break;
          }   
          }  

        }).catch(e => {         
          e.status = "Erro ao verificar email"
          e.classe = "text-danger"
        })

        
      }
    },
    cadastrarUsuarioCompleto(){
      this.promisePostFastApi(this.modelCadastroUsuario, "api/fast_plataforma_usuario/cadastro_usuario").then(res => {
        console.log("cadastrarUsuarioCompleto", res)
        const retorno = JSON.parse(res)
        if (retorno.error) {
          this.exibeToasty(e.description, "error");
          this.fastEmailCadastro.btnCadastrar = true
        } else {
          if (retorno.code == "OK") {
            this.getUsuariosPlataforma(this.$route.params.id_plataforma, 'administradores') 
            this.exibeToasty("Usuário cadastrado com sucesso", "success");
            this.cancelaConviteUsuarios('administradores')
            this.fastEmailCadastro = {
              id_usuario: 0,
              id_pessoa: 0,
              email: "",
              status: "",
              classe: "text-info",
              code: "",
              first_name: "Nome",
              last_name: "não identificado",
              image: "",
              btnCadastrar: true
            }
            this.modelCadastroUsuario = {
              fast_plataforma_usuario: {
                id_plataforma: this.$route.params.id_plataforma,
                administrador: "",
                edita_cursos: "",
                edita_usuarios: "",
                edita_professores: "",
                edita_plataforma: "",
                edita_matriz: "",
                edita_financeiro: "",
                edita_alunos: "",
                edita_admins: "",
                professor: "",
                aluno: "",
                tesouraria: "",
                secretario: "",
                responsavel: ""
              },
              fast_usuario: {
                first_name: "",
                last_name: "",
                email: ""
              },
              fast_pessoa: {
                cpf_cnpj: "",
                datanascimento_abertura: null,
                banco: "",
                codigo_banco: "",
                sexo: "",
              },
              fast_pessoa_endereco: {
                tipo: "",
                uf: "",
                cep: "",
                logradouro: "",
                bairro: "",
                cidade: "",
                complemento: "",
                numero: ""
              },
              fast_pessoa_telefone: {
                tipo: "",
                telefone: "",
                ramal: ""
              },
              fast_usuario_parametro: {
                image: ""
              },
              fast_plataforma_professor: {
                first_name: "",
                last_name: "",
                licenciatura: "",
                image: ""
              },
              envia_email: true
            }
          }
        }
      }).catch(e => {         
        this.exibeToasty("Erro ao cadastrar usuário", "error");
        this.fastEmailCadastro.btnCadastrar = true
      })
    },
    // Editar usuário
    exibeModalEditarUsuario(usuario){
      usuario.edita_cursos = usuario.edita_cursos == "S" ? true : false
      usuario.edita_usuarios = usuario.edita_usuarios == "S" ? true : false
      usuario.edita_professores = usuario.edita_professores == "S" ? true : false
      usuario.edita_plataforma = usuario.edita_plataforma == "S" ? true : false
      usuario.edita_matriz = usuario.edita_matriz == "S" ? true : false
      usuario.edita_financeiro = usuario.edita_financeiro == "S" ? true : false
      usuario.edita_alunos = usuario.edita_alunos == "S" ? true : false
      usuario.edita_admins = usuario.edita_admins == "S" ? true : false

      this.fastUsuarioEditar = usuario
      console.log(usuario)
      this.alteraFastNavegacao('editarAdministradores')      
    },
    // Exclusão
    async confirmaUsuarioExcluir() {
      this.$store.state.fastCarregando = true
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/exclui", this.fastAjaxOptions("POST", JSON.stringify(this.fastUsuarioEditar)));
        let json = await resp.json();
        if (!json.length) {

          let fastAdministradoresTotal = this.fastAdministradoresTotal.filter((u) => { 
              return u.id_usuario != this.fastUsuarioEditar.id_usuario; 
          });

          this.fastAdministradoresTotal = fastAdministradoresTotal
          this.fastAdministradoresFiltro = fastAdministradoresTotal

          this.fastUsuarioEditar = {
            id_usuario: "",
            first_name: "",
            last_name: "",
            image: "",
            ativo: "",
            aceito: "",
            data_convite_envio: "",
            data_aceite: null,
            token: "",
            administrador: "",
            edita_cursos: "",
            edita_usuarios: "",
            edita_professores: "",
            edita_plataforma: "",
            edita_matriz: "",
            edita_financeiro: "",
            edita_alunos: "",
            edita_admins: "",
            professor: "",
            aluno: "",
            tesouraria: "",
            secretario: "",
            responsavel: ""
          }
          this.hideModal("modalUsuarioExcluir");
          this.exibeToasty("Usuário excluído com sucesso", "success")
          this.alteraFastNavegacao('exibirAdministradores')
          this.$store.state.fastCarregando = false
        } else {
          this.exibeToasty("Erro ao excluir", "error")
          this.$store.state.fastCarregando = false
        }
        
      } catch (e) {
        console.log("Erro", e);
      }
    },
    // Desativação
    async confirmaUsuarioDesativar() {
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "N",
        convite_enviado: "N",
        token: "desativado",
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        this.fastUsuarioEditar.ativo = "N"
        this.fastUsuarioEditar.token = "desativado"
        this.hideModal("modalUsuarioDesativar");
        this.exibeToasty("Usuário desativado com sucesso", "success")
        this.$store.state.fastCarregando = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Ativação
     async confirmaUsuarioAtivar() {
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.$route.params.id_plataforma,
        ativo: "S",
        convite_enviado: "S",
        token: "ativado"
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        let obj = Array.from(json);

        this.fastUsuarioEditar.ativo = "S"
        this.fastUsuarioEditar.token = "ativado"

        this.hideModal("modalUsuarioAtivar");
        this.exibeToasty("Usuário ativado com sucesso", "success")
        this.$store.state.fastCarregando = false
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Reenviar convite
    async confirmaUsuarioReenviar(){
      this.$store.state.fastCarregando = true
      const fast_plataforma_usuario = {        
          id_usuario: this.fastUsuarioEditar.id_usuario,
          id_plataforma: this.$route.params.id_plataforma,
          convite_enviado: "S",
          aceito: "N",
          data_aceite: null,
          ativo: "N",
          token: '1',
      };
      try {
        let resp = await fetch(settings.endApiFastEad + "api/fast_plataforma_usuario/atualiza_usuario", this.fastAjaxOptions("POST", JSON.stringify(fast_plataforma_usuario)));
        let json = await resp.json();
        if (json.length) {
          this.fastUsuarioEditar.convite_enviado = "S"
          this.fastUsuarioEditar.aceito = "N"
          this.fastUsuarioEditar.data_aceite = ""
          this.fastUsuarioEditar.ativo = "N"
          this.fastUsuarioEditar.token = json[0].token;

          this.hideModal("modalUsuarioReenviar");
          this.exibeToasty("Convite reenviado com sucesso", "success")
          this.$store.state.fastCarregando = false
        } else {
          this.exibeToasty("Erro ao enviar convite", "error")
          this.$store.state.fastCarregando = false
        }
        
      } catch (e) {
        console.log("Erro", e);
        this.$store.state.fastCarregando = false
      }
    },
    // Emails personalizados
    async getFastPlataformaEmail(id_plataforma, emailConvite, emailConfirmacao) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma_email/lista?id_plataforma=" +
            id_plataforma +
            "&id_email=0",
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {          

          obj.forEach((e) => {
            if (e.ativo == "S") e.ativo = true;
            else e.ativo = false;
            this.validaOpcoesEmail(e)
          });

          let fastEmailConvite = obj.filter(e => e.nome_email == emailConvite)
          if (fastEmailConvite.length) this.fastEmailConvite = fastEmailConvite[0]
          
          let fastEmailConfirmacao = obj.filter(e => e.nome_email == emailConfirmacao)
          if (fastEmailConfirmacao.length) this.fastEmailConfirmacao = fastEmailConfirmacao[0]
        } 
      } catch (e) {
        console.log("Erro", e);
      }
    },
    editarEmail(fastEmail) {
      if (fastEmail.html_email_padrao != fastEmail.html_email) fastEmail.customizado = "S";
      else fastEmail.customizado = "N";
      
      if (fastEmail.ativo) fastEmail.ativo = "S";
      else fastEmail.ativo = "N";

      if (!fastEmail.email_envio && fastEmail.email_destino == "AD") {
        this.exibeToasty("É necessário informar o email de destino", "error")
      } else {
        this.$store.state.fastCarregando = true;
        this.promisePostFastApi(fastEmail, "api/fast_plataforma_email/atualiza").then(res => {
          this.$store.state.fastCarregando = false;
          this.hideModal("modalEditarEmailConvite");
          this.hideModal("modalEditarEmailConfirmacao");
          this.exibeToasty("Email atualizado com sucesso", "success")
        })
        .catch((e) => {
          this.$store.state.fastCarregando = false;
          this.exibeToasty("Erro ao atualizar email", "error")
        })
      }
    },
    // Permissões
    alteraPermissoesUsuario(){
      let fast_plataforma_usuario = {
        id_usuario: this.fastUsuarioEditar.id_usuario,
        id_plataforma: this.fastUsuarioEditar.id_plataforma,
        edita_cursos: this.fastUsuarioEditar.edita_cursos ? "S" : "N",
        edita_usuarios: this.fastUsuarioEditar.edita_usuarios ? "S" : "N",
        edita_professores: this.fastUsuarioEditar.edita_professores ? "S" : "N",
        edita_plataforma: this.fastUsuarioEditar.edita_plataforma ? "S" : "N",
        edita_alunos: this.fastUsuarioEditar.edita_alunos ? "S" : "N",
        edita_matriz: this.fastUsuarioEditar.edita_matriz ? "S" : "N",
        edita_financeiro: this.fastUsuarioEditar.edita_financeiro ? "S" : "N",
        edita_admins: this.fastUsuarioEditar.edita_admins ? "S" : "N"
      }

      this.promisePostFastApi(fast_plataforma_usuario, "api/fast_plataforma_usuario/altera_permissoes").then(res => {
        let json = JSON.stringify(res)
        // console.log("resposta" + JSON.stringify(res))
        if (json.length) {
          if (json.error) {
            this.exibeToasty(json.description, "error");
          } else {
            this.exibeToasty("Permissão alterada com sucesso", "success");
          }
        } else {
          this.exibeToasty("Erro ao buscar alterar permissão", "error");
        }
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar alterar permissão", "error");
      })
    }
  },
};
</script>

<style scope>
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-cursor-refresh {
  cursor: pointer;
}
/* Ajustes de conflito no editor de texto */
/* CSS do fastead faz editor Quill ficar bugada para edição */
[contenteditable="true"] {
  display: block;
}
/* CSS do fastead faz imagem ficar 100% sempre */
[contenteditable="true"] img {
  width: auto;
}
.fd-app-curso-aulas .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item,
.fd-app-meus-cursos .fd-app-curso-aulas-list .fd-app-curso-aulas-list-item {
  font-family: initial;
}

.ql-container {
  height: auto !important;
}
</style>
